:root {
  --primary: #ed4264;
  --primaryVariant: #123780;
  --secondary: #7474bf;
  --error: #ff5555;
  --background: #10101a;
  --surface: #121212;
  --pipe-color: #2e2e46;
}

* {
  scroll-behavior: smooth;
}

/*  remove blue highlight on tap in mobile devices */

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
}

.bg-gradient-love {
  background: #ed4264; /* fallback for old browsers */
  background: radial-gradient(
    #b90571,
    #482782,
    #b90571,
    #130139
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-size: 400% 400%;
  animation: motion-gradient 10s ease-in-out infinite;
}

.bg-gradient-golden {
  background: #b78628; /* fallback for old browsers */
  background: radial-gradient(
    #dba747,
    #ab7628,
    #e6d681,
    #dba747,
    #dba747,
    #703c0f,
    #dba747
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-size: 400% 400%;
  animation: motion-gradient 10s ease-in-out infinite;
}

/* 
.bg-gradient-love::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: red;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.2;
  backdrop-filter: blur("200px");
} */

.gradient-text {
  background: #ed4264; /* fallback for old browsers */
  background: -webkit-radial-gradient(
    #ffedbc,
    #ed4264,
    #7474bf
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: radial-gradient(
    #ffedbc,
    #ed4264,
    #7474bf
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-size: 400% 400%;
  animation: motion-gradient 10s ease-in-out infinite;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes motion-gradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.motion-text {
  animation: motion-text 1s linear infinite;
}

@keyframes motion-text {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(2deg);
  }

  40% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.active-jelly {
  animation: jelly 0.6s ease-in-out infinite;
}

@keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}

/* Frosted Glass Form */

.glass-container {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 1rem;
  /* background-color: rgba(255, 255, 255, 0.3); */
  /* -webkit-backdrop-filter: blur(2em); */
  /* backdrop-filter: blur(2em); */
  padding: 2rem;
  z-index: 999;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .glass-container {
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);
  }
}

.glass-input {
  padding: 0.4rem;
}

.glass-input input,
.glass-input textarea {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  padding: 0.2rem 2rem;
  color: white;
  outline: none;
}

.glass-input .input-opacity-full {
  border-color: rgba(255, 255, 255) !important;
}

.glass-input input:focus,
.glass-input textarea:focus {
  outline: none;
  border-bottom: 2px solid rgba(255, 255, 255, 1);
}

.glass-input label {
  display: flex;
  align-items: center;
  position: absolute;
  color: white;
  pointer-events: none;
}

.glass-input .glass-hint {
  position: absolute;
  right: -25px;
  top: 0;
  background-color: white;
}

.glass-input .glass-hint-error {
  background-color: var(--error);
  color: white;
}

.glass-input .glass-hint:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid white;
  border-bottom: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  top: 0.5rem;
  left: -0.9rem;
}

.glass-input .glass-hint-error:after {
  border-right: 8px solid var(--error);
}

.project-container {
  background: linear-gradient(#10101a, #10101a);
}

.project-card {
  background-color: #1f1f31;
  backdrop-filter: blur(20px);
}

.pipe-container {
  --pipe-gradient-90: linear-gradient(
    90deg,
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    #6cf4f5,
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color)
  );

  --pipe-gradient: linear-gradient(
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    #6cf4f5,
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color),
    var(--pipe-color)
  );
}

@keyframes flow-right {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes flow-left {
  0% {
    background-position: 0 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.pipe-container .pipe-horizontal-left {
  width: calc(100% + 5rem);
  left: -5rem;
  position: relative;
  animation: flow-right 3s ease-in-out infinite;
  background: var(--pipe-gradient-90);
  background-size: 400% 400%;
}

.pipe-container .pipe-horizontal-right {
  width: calc(100% + 5rem);
  animation: flow-left 3s ease-in-out infinite;
  right: -5rem;
  background: var(--pipe-gradient-90);
  background-size: 400% 400%;
}

.pipe-container .pipe-vertical {
  animation: flow-bottom 3s ease-in-out infinite;
  animation-delay: 600ms;
  background: var(--pipe-gradient);
  background-size: 400% 400%;
}

.pipe-container .pipe-vertical-upper {
  /* animation-delay: 1300ms !important; */
}

@keyframes flow-bottom {
  0% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 50% 0;
  }
}

.text-neon {
  text-shadow: 0 0 7px #204052, 0 0 10px #204052, 0 0 21px #204052,
    0 0 42px #80faff, 0 0 82px #80faff, 0 0 92px #80faff, 0 0 102px #80faff,
    0 0 7px #80faff;
  /* animation: flicker 1.5s infinite alternate; */
}

.text-neon-flicker {
  animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 7px #204052, 0 0 10px #204052, 0 0 21px #204052,
      0 0 42px #80faff, 0 0 82px #80faff, 0 0 92px #80faff, 0 0 102px #80faff,
      0 0 7px #80faff;
  }
  20%,
  24%,
  55% {
    text-shadow: none;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
